import React, { Component } from 'react'
import { Col, Container, Row, FormGroup, Label, CustomInput } from 'reactstrap'
import RadioButton from './RadioButton'
import Divider from '../common/Divider'
import * as PropTypes from 'prop-types'

class StepOne extends Component {
  options = {
    NEW_PROJECT: 'New Project',
    EXISTING_PROJECT: 'Existing Project',
  }

  constructor(props) {
    super(props)
    this.state = {
      stepOneSelectedOption: this.options.NEW_PROJECT,
      stepOneDescription: '',
      stepOneAttachmentNames: '',
      stepOneAttachmentFiles: [],
      attachmentErrorSize: false,
      attachmentErrorCount: false,
      attachmentErrorFormat: ''
    }
    this.props.onUpdate(this.state)
  }

  componentDidUpdate = () => {
    this.props.onUpdate(this.state)
  }

  handleRadioButtonClick = id => {
    this.setState({ stepOneSelectedOption: id })
  }

  restrictedFormats = ["ADE", "ADP", "APK", "BAT", "CHM", "CMD", "COM", "CPL", "DLL", "DMG", "EXE", "HTA", "INS", "ISP", "JAR", "JS", "JSE", "LIB", "LNK", "MDE", "MSC", "MSI", "MSP", "MST", "NSH", "PIF", "SCR", "SCT", "SHB", "SYS", "VB", "VBE", "VBS", "VXD", "WSC", "WSF", "WSH", "CAB"];

  handleAttachmentChange = (e) => {
    this.setState({ attachmentErrorSize: false, attachmentErrorCount: false, attachmentErrorFormat: '', stepOneAttachmentNames: '', stepOneAttachmentFiles: [] });
      const { files } = e.target;
      let sizeError = false;
      let countError = false;
      let formatError = false;
      let wrongFormatName = '';
      let filesNames = '';
      let filesArray = [];
      if (files.length > 10) {
        countError = true;
      }
    // eslint-disable-next-line
      Object.keys(files).map(key => {
        const fileFormat = files[key].name.split('.').pop();
        if (this.restrictedFormats.includes(fileFormat.toUpperCase())) {
          formatError = true;
          wrongFormatName = fileFormat.toUpperCase();
        }
        if (files[key].size > 15000000) {
          sizeError = true;
        }
        filesNames += files[key].name + ', ';
        filesArray.push({name: files[key].name, blob: new Blob([files[key]]), format: fileFormat});
      });
      if (countError || sizeError || formatError) {
        this.setState({ attachmentErrorSize: sizeError, attachmentErrorCount: countError, attachmentErrorFormat: wrongFormatName, stepOneAttachmentNames: '', stepOneAttachmentFiles: [] });
      } else {
        this.setState({ attachmentErrorSize: false, attachmentErrorCount: false, attachmentErrorFormat: '', stepOneAttachmentNames: filesNames.slice(0, -2), stepOneAttachmentFiles: filesArray });
      }
  };

  render() {
    return (
      <div>
        <Container className="pt-5">
          <Row className="py-4">
            <Col xs={12} md={1} lg={2}>
              <h1 className="step-label pr-2">1</h1>
            </Col>
            <Col xs={12} md={10} lg={8}>
              <Row>
                <Col xs={12} className="mb-4">
                  <h2 className="m-0 pt-2">Ich möchte...</h2>
                </Col>
                <Col sm={12} md={6} className="pr-3 pb-3">
                  <RadioButton
                    label="ein neues Projekt starten"
                    id={this.options.NEW_PROJECT}
                    onClick={this.handleRadioButtonClick}
                    selected={
                      this.state.stepOneSelectedOption ===
                      this.options.NEW_PROJECT
                    }
                  />
                </Col>
                <Col sm={12} md={6} className="pl-3 pb-3">
                  <RadioButton
                    label="Ein bestehendes Projekt fortsetzen"
                    id={this.options.EXISTING_PROJECT}
                    onClick={this.handleRadioButtonClick}
                    selected={
                      this.state.stepOneSelectedOption ===
                      this.options.EXISTING_PROJECT
                    }
                  />
                </Col>
                <Col xs={12} className="py-3">
                  <textarea
                    onChange={e =>
                      this.setState({ stepOneDescription: e.target.value })
                    }
                    placeholder="Welche Inhalte werden in die App aufgenommen? Was ist die erforderliche Funktionalität? Hauptseitenelemente, Objektkategorien, mögliche Aktionen etc."
                    value={this.state.stepOneDescription}
                    className="form-control description-placeholder form-control-lg"
                    style={{ minHeight: 150 }}
                  />
                </Col>
                <Col xs={12} md={12} lg={12} xl={12} className="pb-3">
                  <FormGroup>
                    <Label for="attachment" className="ml-1">Anhang (zum Beispiel: App-Roadmap, Mockup, Prototyp, Skizze)</Label>
                    <CustomInput className="attachment-label" multiple type="file" label={this.state.stepOneAttachmentNames || "Dateien auswählen..."} id="attachment" name="customFile" onChange={this.handleAttachmentChange} />
                    {this.state.attachmentErrorSize && <div className="text-smallcaps text-red">Die maximale Größe für eine einzelne Datei beträgt 15 MB</div>}
                    {this.state.attachmentErrorCount && <div className="text-smallcaps text-red">Sie können bis zu 10 Dateien hochladen</div>}
                    {this.state.attachmentErrorFormat && <div className="text-smallcaps text-red">{this.state.attachmentErrorFormat}-Format ist nicht erlaubt</div>}
                  </FormGroup>
                </Col>
                <Col xs={12} className="px-0 py-4">
                  <Divider />
                </Col>
              </Row>
            </Col>
            <Col xs={0} md={1} lg={2} />
          </Row>
        </Container>
      </div>
    )
  }
}

StepOne.propTypes = {
  onUpdate: PropTypes.func,
}

export default StepOne
