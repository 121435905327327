import React from 'react'
import { Col, Container } from 'reactstrap'

export const StepZero = () => (
  <Container className="pt-5">
    <Col xs={{ offset: 0, size: 12 }} md={{ offset: 1, size: 10 }} lg={{ offset: 2, size: 8 }}>
      <h2 className="text-justify">
      Wir würden gerne so viel wie möglich darüber wissen, was Sie bauen möchten, um festzustellen, ob wir gut zusammenpassen.
      </h2>
    </Col>
  </Container>
);
